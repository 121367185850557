body {
  background-color: #32325D;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ul #a {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 15%;
  overflow: hidden;
}

li a {
  display: block;
  margin: 0px;
  padding: 0px;
}

.navbar {
  background-color: #3D3D68;
  box-shadow: 0 0 36px 0 rgb(0 0 0 / 30%);

  height: 100%;
  margin: 0px;
  width: 10%;
  padding: 0px;
  position: fixed;
  overflow: auto;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
i,
button,
li,
ul {
  margin: 0px;
  padding: 0px;
}

.nonnavcomp {
  margin-left: 15%;
}

.content {
  margin-left: 10%;
  padding: 20px;
}
.explorercontent {
  margin-right: 10%;
}

button.cp {
  padding: 20px;
  background-color: #1969FF;
  border-style: none;
  border-radius: 5px;
  font-size: 20px;
  width: 15%;
  color: white;
  right: 20px;
  position: fixed;
  box-shadow: rgb(0 0 0 / 20%) 0px -2px 0px inset;
}

textarea {
  resize: none;
}

textarea:focus {
  border: none;
  outline: none;
}

.option {
  background-color: #f8f8f8;
  padding: 50px;
  width: 70%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  border-radius: 20px;
}

.option.a {
  cursor: pointer;
  background-color: #f8f8f8;
  padding: 50px;
  width: 70%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  border-radius: 20px;
}

button.connect {
  background-color: #1969FF;
  border: none;
  padding: 5px;
  margin: 30px 0px 8px 8px;
  color: white;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px -2px 0px inset;
  overflow: hidden;
  width: 80%;

}

.se {
  padding-top: 15px;
  padding-bottom: 15px;
}

.right {
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  overflow: auto;
  text-align: center;
  position: absolute;
  bottom: 45px;
}

.lineone {
  background-color: black;
  opacity: 20%;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 0.5px;
  border-radius: 180px;
}

.linetwo {
  background-color: black;
  opacity: 10%;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 0.5px;
  border-radius: 180px;
}

.address {
  opacity: 90%;
}

.light {
  opacity: 60%;
}

p.a {
  font-size: 125%;
}

p.b {
  font-size: 115%;
}

body {
  color: white;
}

.new {
  background-color: #3D3D68;
  box-shadow: 0 0 36px 0 rgb(0 0 0 / 30%);

  height: 100%;
  margin-left: 80%;
  width: 20%;
  top: 0;
  padding: 20px;
  position: fixed;
  overflow: auto;

}

.container input {
  position: absolute;
  opacity: 0;
}

html,
body {
  width: 100%;
}

.sendPost {
  width: 40%;
  height: 40vh;
  background-color: #3D3D68;
  position: absolute;
  box-shadow: 0 0 36px 0 rgb(0 0 0 / 30%);
  border-radius: 10px;
  top: 10%;
  z-index: 10;
  left: 35%;
  margin-top: 0;
}
html, body{
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
textarea.type {
  background-color: #3D3D68;
  color: white;
  width: 95%;
  height: 30vh;
  display: block;
  border: none;
  font-size: 120%;
  margin-top: 5px;
}
textarea:focus { color: white }
::-webkit-input-placeholder {
  color: rgb(202, 202, 202);
}

:-moz-placeholder { /* Firefox 18- */
  color: rgb(202, 202, 202);
}

::-moz-placeholder {  /* Firefox 19+ */
  color: rgb(202, 202, 202);
}

:-ms-input-placeholder {  
  color: rgb(202, 202, 202);
}
.closeBox {
  font-size: 175%;
  right: 10px;
  position: absolute;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  cursor:pointer;
}
.sendPostButton {
  width: 100px;
  height: 20px;
  border-radius: 10px;
  border: none;
  background-color: #1969FF;
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px -2px 0px inset;
}
pre {
  display: inline;
}
#mypopup {
  display: none;
}
.profileAddress {
  font-size: 150%;
  text-decoration: none;
}
a {
  color: inherit;
  text-decoration: none;
}
.se:hover {
  cursor: pointer;
}
.closeButton {
  margin: 0px 20px 0px 10px;
  font-size: 150%;
  cursor: pointer;
}