input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* ... */
  }
  input[type="radio"] {
  /* remove standard background appearance... */

  /* create custom radio button appearance */
  display: inline-block;
  width: 15px;
  height: 15px;  
  opacity: 30%;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid #060c3b;
  border-radius: 50%;
}
input[type="radio"]:checked {
    background-color: blue;
    border: 2px solid blue;
    opacity: 100%;
  }
  label {
    font-size: 18px;
  }
  input.otherInput:focus {
    border: none;
  outline: none;
  }
  input.otherInput {
    border: none;
    border-radius: 5px;
  outline: none;
  box-shadow: rgb(0 0 0 / 20%) 0px -2px 0px inset;
  }